/// <reference types="@types/googlemaps" />
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceAutocompleteOptions, Options } from '../../../models/models';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { EditorStyle, LabelMode } from 'devextreme/common';
import { CommonModule } from '@angular/common';
import { DxTextBoxModule, DxValidatorModule } from 'devextreme-angular';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';

@Component({
	standalone: true,
	imports: [CommonModule, DxTextBoxModule, DxValidatorModule, DxiValidationRuleModule],
	selector: 'app-address-autocomplete',
	templateUrl: './address-autocomplete.component.html',
	styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements OnInit, AfterViewInit, OnChanges {
	addressAutoCompleteInstance!: google.maps.places.Autocomplete;
	@Input() label!: string;
	@Input() autocompleteOptions!: GooglePlaceAutocompleteOptions;
	@Input() stylingMode!: EditorStyle;
	@Input() labelMode!: LabelMode;
	@Input() isRequired: boolean = false;
	@Input() requiredMessage!: string;
	@Input() formControlName!: string;
	@Output() onValueChange: EventEmitter<AddressInfo> = new EventEmitter();
	@Output() onPlaceSelect: EventEmitter<AddressInfo> = new EventEmitter();

	@ViewChild('autocomplete') autocomplete!: any;
	@Input() autoCompleteValue!: string;
	@Input() id!: string;

	controlsOption: Options = {
		// default height
		height: '30px',
	};

	constructor(private cdRef: ChangeDetectorRef) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.autocompleteOptions) {
			this.addressAutoCompleteInstance.setComponentRestrictions({
				country: this.autocompleteOptions?.overridableCountries ?? [],
			});

			if (this.autocompleteOptions?.options) {
				this.controlsOption = this.autocompleteOptions.options;
			}
		}
	}

	ngAfterViewInit(): void {
		this.initializeControl();
	}

	initializeControl() {
		if (this.autocomplete && this.autocomplete.instance && this.autocomplete.instance._labelContainerElement) {
			const htmlInputElement: HTMLInputElement = this.autocomplete.instance._labelContainerElement as HTMLInputElement;
			this.addressAutoCompleteInstance = new google.maps.places.Autocomplete(htmlInputElement, this.autocompleteOptions);

			this.addressAutoCompleteInstance.setComponentRestrictions({
				country: this.autocompleteOptions?.overridableCountries ?? [],
			});
			google.maps.event.addListener(this.addressAutoCompleteInstance, 'place_changed', () => {
				const place = this.addressAutoCompleteInstance.getPlace();
				const address = place as Address;
				this.invokeEvent(address);
			});
		}
	}

	private invokeEvent(data: Address) {
		if (data.address_components) {
			const addressInfo: AddressInfo = {
				// Address parameters returns the formatted address
				name: data.name ?? '',
				address: `${this.getAddressComponent(data, 'street_number')?.short_name ?? ''} ${this.getAddressComponent(data, 'route')?.short_name ?? ''}`.trim(),
				city: this.getAddressComponent(data, 'locality')?.short_name ?? this.getAddressComponent(data, 'administrative_area_level_2')?.short_name,
				state: this.getAddressComponent(data, 'administrative_area_level_1')?.short_name,
				zip: this.getAddressComponent(data, 'postal_code')?.short_name,
				country: this.getAddressComponent(data, 'country')?.short_name,
				latitude: data.geometry.location.lat().toString(),
				longitude: data.geometry.location.lng().toString(),
				formatted_address: data.formatted_address,
			};
			this.onPlaceSelect.emit(addressInfo);
		} else {
			if (data.name || data.formatted_address) {
				const addressInfo: AddressInfo = {
					// Address parameters returns the formatted address
					formatted_address: data.formatted_address,
					name: data.name,
					//address: `${this.getAddressComponent(data, 'street_number')?.short_name ?? ''} ${this.getAddressComponent(data, 'route')?.short_name ?? ''}`,

					// city: this.getAddressComponent(data, 'locality')?.short_name ?? this.getAddressComponent(data, 'administrative_area_level_2')?.short_name,
					// state: this.getAddressComponent(data, 'administrative_area_level_1')?.short_name,
					// zip: this.getAddressComponent(data, 'postal_code')?.short_name,
					// country: this.getAddressComponent(data, 'country')?.short_name,
					// latitude: data.geometry.location.lat().toString(),
					// longitude: data.geometry.location.lng().toString()
				};
				this.autoCompleteValue = addressInfo.name ?? '';
				this.onPlaceSelect.emit(addressInfo);
			} else {
				this.onPlaceSelect.emit(undefined);
			}
		}
	}
	getAddressComponent(address: Address, typeName: string) {
		return address.address_components.find((x) => x.types.some((y) => y === typeName));
	}

	/**
	 * Get's full address leaving the country, state, zip and city parts.
	 * Part's of google address options
	 * 	locality: 'city'
	 * 	administrative_area_level_2 : 'city'
	 * 	administrative_area_level_1:  'state'
	 * 	postal_code : 'zip code'
	 * 	country: 'country'
	 * @param address: Address object
	 * @returns
	 */
	getFullAddress(address: Address): string {
		const addressParts: string[] = [];
		address.address_components.forEach((address_component: AddressComponent) => {
			if (
				!address_component.types.includes('locality') &&
				!address_component.types.includes('administrative_area_level_2') &&
				!address_component.types.includes('administrative_area_level_1') &&
				!address_component.types.includes('postal_code') &&
				!address_component.types.includes('country')
			) {
				addressParts.push(address_component.long_name);
			}
		});
		return addressParts.join();
	}

	/**
	 * Value change event captures the value if not selected from google autocomplete suggestions
	 * @param data
	 */
	handleValueChange(data: any) {
		const addressInfo: AddressInfo = {
			address: data?.value ?? '',
			name: data?.value ?? '',
			formatted_address: data?.value ?? '',
		};
		this.autoCompleteValue = data?.value ?? '';
		this.onValueChange.emit(addressInfo);
		this.cdRef.detectChanges();
	}
}

export interface AddressInfo {
	name?: string | undefined;
	address?: string | undefined;
	city?: string | undefined;
	state?: string | undefined;
	zip?: string | undefined;
	latitude?: string | undefined;
	longitude?: string | undefined;
	branchWebsiteUrl?: string | undefined;
	country?: string | undefined;
	formatted_address?: string | undefined;
}
